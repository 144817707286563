import React, { useEffect, useState, useRef } from 'react';
import * as styles from '../Projects/styles/project.module.css';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import Layout from '../components/Layout';
import { graphql, Link } from 'gatsby';

export default function Projects({ data }) {
  const projects = data.allProjectDummyJson.nodes;
  const tl = gsap.timeline();
  gsap.registerPlugin(ScrollTrigger);

  const [off, setOff] = useState(0);
  const handleScroll = () => setOff(window.pageYOffset);

  let projectBanner = useRef(null);
  let projectSection = useRef(null);
  let projectHeader = useRef(null);
  let projectText = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // useEffect(() => {
  //   tl.from(projectHeader, 1, {
  //     y: -50,
  //     ease: 'power4.out',
  //     delay: 0.2,
  //     opacity: 0,
  //   })

  //     .from(projectText, 0.6, {
  //       y: 50,
  //       ease: 'power4.out',
  //       delay: 0.1,
  //       opacity: 0,
  //     })

  //     .from(projectSection, 1, {
  //       y: 100,
  //       opacity: 0,
  //       ease: 'power3.easeOut',
  //       delay: 0.3,
  //       stagger: {
  //         amount: 0.4,
  //       },
  //       scrollTrigger: {
  //         trigger: projectBanner,
  //         start: '50%',
  //         end: '100%',
  //         pinSpacing: false,
  //         scrub: true,
  //       },
  //     });
  // }, []);

  return (
    <Layout>
      <div className={styles.project_banner_wrapper}>
        <div>
          <div
            className={styles.project_banner}
            ref={(el) => {
              projectBanner = el;
            }}>
            <h1 className={styles.project_banner_bg_txt} data-text='WORKS'>
              WORKS
            </h1>
            <div
              className={styles.project_banner_content}
              style={{ transform: `translateY(${off * -0.9}px)` }}>
              <h3
                className={styles.project_banner_content_header}
                ref={(el) => {
                  projectHeader = el;
                }}>
                PROJECTS
              </h3>
              <p
                className={styles.project_banner_content_text}
                ref={(el) => {
                  projectText = el;
                }}>
                Creativity involves breaking out of expected and repeatable
                patterns in order to look at things in different way than ever
                before. Explore our recent works
              </p>
            </div>
          </div>
        </div>

        <div className={styles.projectSection}>
          <div
            className={styles.project_wrapper}
            ref={(el) => {
              projectSection = el;
            }}>
            {projects.map(
              (data: {
                id: React.Key;
                img: string;
                subTopic: string;
                topic: string;
                slug: any;
              }) => {
                return (
                  <div key={data.id} className={styles.gridWrap}>
                    <div className={styles.imgWrapper}>
                      <img src={data.img} alt='' />
                    </div>
                    <div className={styles.project_contents}>
                      <p className={styles.project_subTopic}>{data.subTopic}</p>
                      <p className={styles.project_topic}>{data.topic}</p>
                      <Link
                        to={'/Projects/Project/' + data.slug}
                        className={styles.project_link}>
                        view project{' '}
                        <span className={styles.project_link_icon}>{'>'}</span>
                      </Link>
                    </div>
                  </div>
                );
              },
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ProjectsPage {
    allProjectDummyJson {
      nodes {
        id
        img
        topic
        subTopic
        slug
      }
    }
  }
`;
